import React, { Suspense, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import {
  BrowserRouter, Route, Routes,
  useNavigate
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import './App.scss';

import SelectPrinter from 'SelectPrinter';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Loading from 'components/atoms/Loading';
import Text from 'components/atoms/Text';
import CustomModal from 'components/organisms/Modal';
import Mainlayout from 'components/templates/Mainlayout';
import useGA from 'hooks/useGA';
import useLanguage from 'hooks/useLanguage';
import useOfflineSubmit from 'hooks/useOfflineSubmittion';
import { detectLanguage } from 'i18n';
// import HomeNav from 'navigations/HomeNav';
import useRoutesList from 'routes';
import { setLocalStorage } from 'services/common/storage';
import { persistor, store } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMenusAction } from 'store/menus';
import { getQuestionsAction } from 'store/questions';
import {
  getGeneralAction, getHeaderFootersAction, getStaticSlugAction, setMessageNotify
} from 'store/systems';
import { LANGUAGE } from 'utils/constants';
import { mappingURLToExternal } from 'utils/functions';
import { LanguageKey, checkActiveLang } from 'utils/languages';

const AppWrapper: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { messageNotify } = useAppSelector((state) => state.systems);

  const translations = useAppSelector((state) => state.systems.feTranslations);
  const general = useAppSelector((state) => state.systems.general);

  useGA();
  useOfflineSubmit();
  SelectPrinter();

  const { handleShowModal } = useLanguage();
  const { routes } = useRoutesList();

  useEffect(() => {
    if (i18n.language) {
      const languageKey = detectLanguage() as LanguageKey;
      dispatch(getGeneralAction())
        .unwrap()
        .then((res) => {
          if (!checkActiveLang(languageKey, res.locales)) {
            handleShowModal(languageKey, 'Hiện tại ngôn ngữ chưa khả dụng. Vui lòng quay lại sau.');
          }
        })
        .catch(() => {
          if (!checkActiveLang(languageKey, general?.locales)) {
            handleShowModal(languageKey, 'Hiện tại ngôn ngữ chưa khả dụng. Vui lòng quay lại sau.');
          }
        })
        .finally(() => {
          dispatch(getHeaderFootersAction());
          dispatch(getMenusAction());
          dispatch(getQuestionsAction());
          dispatch(getStaticSlugAction());
        });
    }
  }, [dispatch, i18n.language, handleShowModal]);

  useEffect(() => {
    if (translations && i18n.language) {
      try {
        Object.keys(translations).forEach((lang) => {
          if (translations[lang] && translations[lang]?.translation
            && Object.values(translations[lang]?.translation).length > 0) {
            i18n.addResourceBundle(lang, 'translation', translations[lang].translation);
          }
        });
      } catch {
        // Throw
      }
    }
  }, [translations, i18n.language]);

  if (!routes) {
    return <Loading isShow />;
  }

  return (
    <Suspense fallback={null}>
      <Helmet>
        <title>{general?.seoData.title || 'Caremeyeulayminh'}</title>
        <meta
          name="description"
          content={general?.seoData.description}
        />
        <meta name="keyword" content={general?.seoData.keywords} />
        <meta name="og:url" property="og:url" content={window.location.href} />
        <meta
          name="og:title"
          property="og:title"
          content={general?.seoData.title}
        />
        <meta
          name="og:description"
          property="og:description"
          content={general?.seoData.description}
        />
        <meta
          name="og:image"
          property="og:image"
          content={mappingURLToExternal(general?.seoData.image)}
        />
      </Helmet>
      <CustomModal
        isOpen={messageNotify?.isOpen}
        handleClose={() => dispatch(setMessageNotify({
          ...messageNotify,
          isOpen: false
        }))}
        variant="notification"
      >
        <div className="o-modal_iconError">
          <Icon iconName="error" size="32" />
        </div>
        <Text modifiers={['center', '600', '20x28', 'fireEngineRed']}>
          {messageNotify?.message}
        </Text>
        <div className="o-modal_button">
          <Button
            variant="secondary"
            sizes="h60"
            handleClick={() => {
              dispatch(setMessageNotify({
                ...messageNotify,
                isOpen: false
              }));
              i18n.changeLanguage('vi');
              setLocalStorage(LANGUAGE, 'vi');
              navigate('/', { replace: true });
            }}
          >
            <Text modifiers={['600', '20x28', 'darkTangerine']}>
              OK
            </Text>
          </Button>
        </div>
      </CustomModal>
      <Routes>
        <Route element={<Mainlayout />}>
          {routes
            && (Object.keys(routes) as Array<keyof typeof routes>).map(
              (ele) => routes[ele].path.map((item, index) => (
                <Route
                  key={`router-${ele}-${index.toString()}`}
                  path={item}
                  element={routes[ele].element}
                />
              )),
            )}
        </Route>
        {/* <Route path="/" element={<HomeNav />} />
        <Route path="/en" element={<HomeNav />} /> */}
      </Routes>
    </Suspense>
  );
};

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  });
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <HelmetProvider context={{}}>
            <BrowserRouter>
              <AppWrapper />
            </BrowserRouter>
            <ToastContainer />
          </HelmetProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
